const BDS = {
    "api-kaps": {
        "apiKey": "AIzaSyCIH1HtwhESYQoz9_JDLDFqxIsXz1YfELw",
        "authDomain": "api-kaps.firebaseapp.com",
        "projectId": "api-kaps",
        "appId": "1:222654951856:web:ee3a8496bc560831d2a675"
    },
    "tgd": {
        "apiKey": "AIzaSyAB0pH-vHsji3_tR4xqEl8ZaZ7xvZ0ZOEI",
        "authDomain": "the-good-driver.firebaseapp.com",
        "projectId": "the-good-driver",
        "appId": "1:258587131765:web:568bc9a70085482e2c1d3c"
    },
    "snowfy": {
        "apiKey": "AIzaSyBQrwegYWQEmOBBsdgv8qbfR7bv0SyP9lo",
        "authDomain": "snowfy-kaps.firebaseapp.com",
        "projectId": "snowfy-kaps",
        "appId": "1:365142813166:web:f192d14ced5f5f692106ff"
    },
    "remoto": {
        "apiKey": "AIzaSyABvFLtT4eprFwakTWjKaq0yeuKG3oOd10",
        "authDomain": "remoto-2.firebaseapp.com",
        "projectId": "remoto-2",
        "appId": "1:421530974832:web:dad01c47cefed514",
    }, 
    "caser": {
        "apiKey": "AIzaSyAB0pH-vHsji3_tR4xqEl8ZaZ7xvZ0ZOEI",
        "authDomain": "the-good-driver.firebaseapp.com",
        "projectId": "the-good-driver",
        "appId": "1:258587131765:web:568bc9a70085482e2c1d3c"
    },
    "caj": {
        "apiKey": "AIzaSyCT4ThPDa78PR6u9B_1cO-YGmJLfg7KXi0",
        "authDomain": "caser-autos-jovenes.firebaseapp.com",
        "projectId": "caser-autos-jovenes",
        // storageBucket: "caser-autos-jovenes.appspot.com",
        // messagingSenderId: "88680457821",
        "appId": "1:88680457821:web:58ed8852681009820ccfb4",
        // measurementId: "G-GS5GRT6MZC"
    }
};

export default BDS;