import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getFirestore, collection, orderBy, limit, startAfter, query, getDocs, onSnapshot, doc, getDoc, updateDoc, setDoc, where, arrayUnion, deleteDoc } from 'firebase/firestore'
import { getPrj } from '../constants/methods';
import BDS from '../constants/bds'
import axios from 'axios'

const n = getPrj();
const app = initializeApp(BDS[n]);

const logOut = async () => {
    const au = getAuth(app);
    await signOut(au);
    window.localStorage.clear()
}

const getData = async (params) => {
    const db = getFirestore(app);
    const {coll, lim, start, order, service='TGD'} = params;
    const q = query(collection(db, coll), orderBy(order), startAfter(start), limit(lim), where('service', 'array-contains', service))
    const snapshot = await getDocs(q);  
    return snapshot;
}

const getDocu = async (params) => {
    const db = getFirestore(app);
    const {coll, docu} = params;
    const ref1 = doc( db, coll, docu?.toString());
    const ref2 = await getDoc(ref1);
    let ret = ref2?.data() || null;
    return ret;
}

const getDocus = async (params) => {
    const db = getFirestore(app);
    const {coll, service=''} = params;      
    const q = service && service != '' ? 
                query(collection(db, coll), where('service', '==', service)) :
                query(collection(db, coll))
    const snapshot = await getDocs(q);  
    let ret = [];
    snapshot?.forEach(doc => { ret.push(doc.data())})
    return ret;
}

const setDocu = async (params) => {
    try {
        const db = getFirestore(app);
        const {coll, docu, data, merge=false} = params;
        const ref = doc( db, coll, docu.toString());
        //await updateDoc(ref, data)
        delete data.merge
        await setDoc(ref, data, {merge:merge})
        //await setDoc(ref, data)
        return true;
    } catch(e) {
        console.error(e)
        return false;
    }
}

const updateDocu = async (params) => {
    try {
        const db = getFirestore(app);
        const {coll, docu, data} = params;
        const ref = doc( db, coll, docu.toString());
        await updateDoc(ref, data);
        return true;
    } catch(e) {
        console.error(e);
        return false;
    }
}


const deleteDocu = async (params) => {
    try {
        const db = getFirestore(app);
        const {coll, docu} = params;
        const ref = doc( db, coll, docu.toString());
        await deleteDoc(ref);
        return true;
    } catch(e) {
        console.error(e);
        return false;
    }
}

const getHook = (params, snapshot, error) => {
    const db = getFirestore(app);
    const {coll, lim, order, service='VERISURE'} = params;
    const q = query(collection(db, coll), where('service', 'array-contains', service) , orderBy(order, 'desc'), limit(lim))
    return onSnapshot(q, snapshot, error);
};

const getHookB = (params, snapshot, error) => {
    const db = getFirestore(app);
    const {coll, id, service} = params;
// console.log(coll, service, id)    
    const q = query(collection(db, coll), where('service', '==', service), where('name', '==', id))
    return onSnapshot(q, snapshot, error);
};

const getWatchingHook = (params, snapshot, error) => {
    const db = getFirestore(app);
    const {coll, alertId=null} = params;
    return onSnapshot(doc(db, coll, alertId), snapshot, error);
};

const getWatchingReport = async (params) => {
    const db = getFirestore(app);
    const { alertId } = params;
    const pluspos = alertId.indexOf('+');
    const timestamp = Number(alertId.substring(0, pluspos));
    const d = new Date(timestamp);
    const phone = alertId.substring(pluspos);
    const y = d.getFullYear().toString();
    const m = (d.getMonth()+1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const ref1 = doc( db, `crashReports/${y}/${m}/${day}/${phone}`, timestamp.toString());
    const ref2 = await getDoc(ref1);
    //const doc = ref2; // await getDoc(collection(ref1, `${day}/${phone}`), alertId)
    let ret = ref2.data();
    if(ret?.hasLocations) ret.report = (await getDoc(await doc(ref1, 'heavyData', 'loc'))).data();
    return ret;
    /* 
    db.collection('crashReports').doc(syear)
        .collection(smonth).doc(sday)
        .collection(sphone).doc(id.toString()
    */
    //return getDoc(doc(db, coll, alertId));
}

const getWatchingImpacts = async (params) => {
    const { alertId, hasAccelerations, impacts } = params;
//console.log(impacts, hasAccelerations)    
    let ret = [];
    if(hasAccelerations && !impacts) {
        const db = getFirestore(app);
        const pluspos = alertId.indexOf('+');
        const timestamp = Number(alertId.substring(0, pluspos));
        const d = new Date(timestamp);
        const phone = alertId.substring(pluspos);
        const y = d.getFullYear().toString();
        const m = (d.getMonth()+1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        ///crashReports C /2022 D /04 C /04 D /+34656609838 C /1649063541767 D /heavyData C /acc D
        const ref1 = await doc( db, `crashReports/${y}/${m}/${day}/${phone}/${timestamp.toString()}/heavyData/`, 'acc');
        const ref2 = await getDoc(ref1);
        ret = ref2?.data()?.accelerations.map(a => {
            const d = new Date(a.time || a.accTime)
            const time = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${d.getMilliseconds()}`
            return [time, a.accModule || a.mod]
        });
    }
    return ret;
}

const setSessionID = async (params) => {
    const { user, sessionID } = params;
    const db = getFirestore(app);
    const ref = doc( db, `users/`, user);
    /* await updateDoc(ref, {
        "sessionIDs": deleteField()
    }); */
    await updateDoc(ref, {
        "sessionIDs": [sessionID]
    })
    window.localStorage.setItem('sessionID', sessionID);
    const docData = await getDoc(ref);
    const service = docData?.data()?.service;
    window.localStorage.setItem('serviceID', service);
    return service;
}

const getUser = async (mail) => {
    if(!mail) return null;
    try {
        const db = getFirestore(app);
        const ref = doc( db, `users/`, mail);
        const docData = await getDoc(ref);
        const user = docData?.data();
        // console.log(`firebaseConfig -> getUserMenu ${JSON.stringify(rol)}`)
        return user;
    } catch(e) {
        console.log(`firebaseConfig -> getUserMenu: Error getting user rol ${e.message}`)
        console.log(e)
    }
}

const getStatistics = async (data) => {
    const tk = data.tk;
    delete(data.tk)
    const urlBase = process.env.REACT_APP_API_URL
    const ret = await axios({
        method: "post",
        url: `${urlBase}/statistics/${data.service.toLowerCase()}/getStats`,
        data: data, 
        headers: { Authorization: `Bearer ${tk}`}
    });
    const jasoncito = ret?.data?.detail;
    return  typeof jasoncito == 'object' ? jasoncito : JSON.parse(ret?.data?.detail);
}

const closeAlert = async (data) => {
    const { tk, service } = data;
    delete(data.tk);
    delete(data.service);
    const urlBase = process.env.REACT_APP_API_URL;

    const ret = await axios({
        method: "post",
        url: `${urlBase}/alerts/${service.toLowerCase()}/setStatus`,
        data: data, 
        headers: { Authorization: `Bearer ${tk}`}
    });
    const jasoncito = ret?.data;
    return  typeof jasoncito == 'object' ? jasoncito : JSON.parse(ret?.data?.detail);
}

const addToArray = async (params) => {
    //({coll: 'simulations', docu: id, arr: 'runtimes', data: docu})
    try {
        const db = getFirestore(app);
        const { coll, docu, arr, data } = params;

        const ref = doc( db, coll, docu.toString());
        await updateDoc(ref, {
            [arr]: arrayUnion(data)
            //'runtimes': arrayUnion('runtimes', ...[+new Date])
        })
        return true;
    } catch(e) {
        console.error(e.message)
        return false;
    }
}

const callApi = async (data) => {
    const { tk, module, method, service } = data;
    delete(data.tk)
    delete(data.module);
    delete(data.method);
    delete(data.service);
    const urlBase = process.env.REACT_APP_API_URL
    const ret = await axios({
        method: "post",
        url: `${urlBase}/${module}/${service.toLowerCase()}/${ method }`,
        data: data, 
        headers: { Authorization: `Bearer ${tk}`}
    });
    const json = ret?.data?.detail || [];
    return typeof json == 'object'? json : JSON.parse(json);
}

export { 
    app,
    initializeApp,
    signInWithEmailAndPassword,
    getFirestore,
    getAuth,
    logOut,
    getData,
    getHook,
    getHookB,
    getWatchingHook,
    getWatchingReport,
    getWatchingImpacts,
    setSessionID,
    getDocu,
    getDocus,
    setDocu,
    getUser,
    getStatistics,
    addToArray,
    updateDocu,
    deleteDocu,
    closeAlert,
    callApi
}