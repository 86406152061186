import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";

import { getHook } from "../context/firebaseConfig";
import { useFirebaseAuth } from "../context/AuthContext";

import MyHeader from "../components/Header";
import MyMenu from "../components/Menu";


const {Content} = Layout;

const containerStyle = {
    width: '100%',
    height: '100vh'
  };
  
const center = {
    lat: 40.5,
    lng: -3.5
};
  
const MappedAlerts = () => {
    const nav = useNavigate();
    const {t} = useTranslation();
    const [user] = useState(useFirebaseAuth());
    const [markers, setMarks] = useState([]);
      
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDn38IJ8ElyvBI0NJp9Psf0KJeW_ajGsk4" //"AIzaSyA1T5gTSGarzG3wFv1zo1UE-th6wDkBAJE"
    })
        
    useEffect(() => {
        const unsubscribe = getHook({coll: 'alerts', order: 'alertId', service: user.service, lim: 100},
            (querySnapshot) => {
                const lst = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                setMarks(lst);
            },
            (error) => alert('kk de la vaca')
        );
        return unsubscribe;
    }, [setMarks]);

    return (
        <React.Fragment>
            {/* <Layout>
                <MyHeader title={t('BUTTON_MAPPED_ALERTS')}/>
                <Content style={{display: "flex"}}>
                    <MyMenu /> */}
                    {isLoaded &&
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={7}
                            //onLoad={onLoad}
                            //onUnmount={onUnmount}
                        >
                            {markers.map(l => (
                                <Marker 
                                    position={{lat:l.latitude || l.lat, lng:l.longitude || l.long}} 
                                    icon={`/assets/img/${l.viewed?'':'no'}visto.gif`} 
                                    onClick={() => nav('/watch', { state: { data:l, type: 'alerts', petet:'si'}})}
                                />
                            ))}
                        </GoogleMap>
                    }
               {/*  </Content>
            </Layout> */}
        </React.Fragment>
    )
}

export default MappedAlerts;