import { Outlet, Navigate } from 'react-router';
import {useFirebaseAuth} from './context/AuthContext.js';

const MenuInterceptor = (props) => {
  const a = useFirebaseAuth();
  //console.log(props)
  let go = true;
  switch (props.p) {
    case 'dashboard':
      if(a?.rol?.indexOf('OPERATOR') > -1) return <Navigate to='/mapped' />;
    case 'pittpanel':
    case 'simulatorpanel':
      go = a?.rol?.indexOf('ADMIN') > -1 || a?.rol?.indexOf('MANAGEMENT') > -1;
      break;
    case 'alerts':
    case 'cancelled':
    case 'closed':
    case 'mapped':
    case 'watch':
      go = a?.rol?.indexOf('ADMIN') > -1 || a?.rol?.indexOf('OPERATOR') > -1;
      break;
  }
  return  go ? <Outlet /> : <Navigate to='/login' />;
}

export default MenuInterceptor