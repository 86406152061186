const petete = Object.freeze({
    getPrj: () => {
        const path = window.location.hostname;
        var ret = "tgd";
        ret = path.match(/^[^.]+/) ? path.match(/^[^.]+/)[0] : "tgd";
        if(ret.indexOf('-')>-1) ret = ret.substring(0, ret.indexOf('-'));
        if(ret == 'panel' || ret == 'kaps' || ret == 'test') ret = 'tgd';
        // if(ret.indexOf('-t3st')>-1) ret = ret.substring(0, ret.indexOf('-t3st'));
        // if(ret.indexOf('-helpy')>-1) ret = ret.substring(0, ret.indexOf('-helpy'));
        // if(ret.indexOf('.helpyplus')>-1) ret = ret.substring(0, ret.indexOf('.helpyplus'));
        // if(ret.indexOf('.helpysafe')>-1) ret = ret.substring(0, ret.indexOf('.helpysafe'));
        return ret;
    }
})

module.exports = petete;