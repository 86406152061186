
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {app, getAuth, getWatchingHook, getUser, logOut, getHook } from "./firebaseConfig";
import Loading from '../components/Loading'

const Auth = React.createContext({ name: '', user: null, menu: null });

const AuthContext = ({ children }) => {
    const [audio] = useState(new Audio('https://firebasestorage.googleapis.com/v0/b/api-kaps.appspot.com/o/bell2.mp3?alt=media'));
    const nav = useNavigate();
    const [user, setUsuario] = useState(null);
    const [showChild, setShowChild] = useState(false);
    const [sessionID, setSessionID] = useState();

    useEffect(() => {
        const au = getAuth(app);
        au.onAuthStateChanged(async (user) =>{
            const cus = au.currentUser;
            const hayUser = cus != null;
            if(hayUser) {
                const dbUser = await getUser(user?.email)
                if(dbUser) {
                    user.rol = dbUser.rol;
                    user.service = dbUser.service;
                    user.sessionID = dbUser.sessionIDs[0];
                }
                setUsuario(user);
            } else {
                setUsuario(null)
            }
            setShowChild(true);
        });
    }, []);
 
    useEffect(() => {
        if(user) {
            const ssId = localStorage.getItem('sessionID')
            setSessionID(ssId);
            const unsubscribe = getWatchingHook({coll: 'users', alertId: `${user?.email}`},
                (querySnapshot) => {
                    const d = querySnapshot.data()                 
                    if(!d.sessionIDs || isNaN(ssId) || Number(ssId) != d.sessionIDs[0]) { 
                        nav('/login') 
                    }
                },
                (error) => alert('kk de la vaca')
            );
            return unsubscribe;
        } 
    }, [user]);

    useEffect(() => {
        if(user?.service) {
            const hook = getHook({coll: 'alerts', order: 'alertId', lim: 1, service: user.service},
                (querySnapshot) => {
                    const lst = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                    !lst[0]?.viewed ? audio.play() : audio.pause();
                },
                (error) => {console.log(error); alert('kk de la vaca');}
            );
            return hook;
        } 
    }, [user]);

    if (!showChild) {
        return <Loading/>;
    } else {
        return (
            <Auth.Provider value={{ user }} key={+new Date}>
                {children}
            </Auth.Provider>
        );
    }    
};
function useFirebaseAuth() {
    const context = React.useContext(Auth);
    if (context === undefined) {
        throw new Error(
            "useFirebaseAuth must be used within a FirebaseAuthProvider"
        );
    }
    return context.user;
}
  
export { Auth, AuthContext, useFirebaseAuth };