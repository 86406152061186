import React, { useState, useEffect } from "react";
import { Layout, Col, Row } from "antd";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
//import { withRouter } from "react-router";
import {
    app, getAuth, setSessionID, signInWithEmailAndPassword
} from "../context/firebaseConfig";
//import Error from '../components/Error'
import { useTranslation } from 'react-i18next';
import { getPrj } from "../constants/methods";

import { useLocation, useNavigate } from "react-router-dom";

import { useFirebaseAuth } from "../context/AuthContext";

import '../assets/css/login.css'
import moment from "moment";
import { useForm } from "antd/es/form/Form";

const FIRST_PAGE = '/pittpanel'; // '/dashboard';

const Login = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const { t } = useTranslation();
    const { Footer } = Layout;
    const [form] = useForm();
    const [error, seterror] = useState('')
    const [auth, setAuth] = useState();
/* 
    useEffect(() => {
        if (user && auth.currentUser) {
           // history.push("/private");
        }
    }, [history, user]);
 */
    useEffect(()=> {
        window.localStorage.clear();
        setAuth(getAuth(app));
    }, []);
    const withEmail = async e => {
        //e.preventDefault();
        const { user, password } = e;
        await signInWithEmailAndPassword(auth, user, password)
            .then(async result => {
                let to = (window.history.state && window.history.state.idx > 0) ? -1 : FIRST_PAGE;
                if(loc.pathname === '/login') to = FIRST_PAGE;
                // Actualizar el sessionID de la base de datos
                await setSessionID({user: auth?.currentUser?.email, sessionID: +new Date});
                return nav(to);
            })
            .catch(error => {
                console.log(error);                
                seterror(t(error.code))
            });
    };

    return (
        <Layout style={{ height: "90vh" }}>
            <Row justify='center' align='middle' style={{ height: "80vh" }}>
                <Col span={12} style={{ "textAlign": "center"}}>
                    <img src={`/assets/img/${getPrj()}/logo.png`} alt="" />
                </Col>
                <Col span={12} style={{ 
                    display: "flex", 
                    "justifyContent": "center"
                    }}>
                    <div className='d-flex bg-light align-items-center text-center'
                        style={{
                            padding: 24,
                            width: 400
                        }}
                    >
                        {(
                            <Form className="login-form" onFinish={withEmail} form={form}>
                                <Form.Item>
                                    <h1 className='text-primary'>{t('LOGIN')}</h1>
                                </Form.Item>
                                {error? <Form.Item>{error}</Form.Item>:null}
                                <Form.Item
                                    name="user"
                                    rules={[{ required: true, message: t('ERRMSG_FIELD_REQUIRED') }]}
                                >
                                    <Input 
                                        prefix={<UserOutlined  style={{ color: "rgba(0,0,0,.25)" }} /> } 
                                        placeholder={t('USERNAME')} 
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: t('ERRMSG_FIELD_REQUIRED') }]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined style={{color: "rgba(0,0,0,.25)"}}
                                            />
                                        }
                                        type="password"
                                        placeholder={t('PASSWORD')}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        style={{ marginRight: 10 }}
                                    >
                                        {t('BUTTON_LOGIN')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </div>
                </Col>
            </Row>
            <Footer className="fixed-bottom d-none d-sm-block" style={{ textAlign: "center" }}>
                <span>version: { moment().format('YYMMDDHHmmss') } </span> Powered by <a href='https://kaps.es'> Kaps Management Group</a>
            </Footer>
        </Layout>
    );
};
export default Login;