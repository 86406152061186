import './App.css';
import Views from './Views'
import "antd/dist/antd.min.css";

const App = () => {
  return (
    <Views />
  )
}

export default App;